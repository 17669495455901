<template>
  <div class="list_main">
    <a-spin :spinning="loading" :delay="delayTime">
    <div class="course_item_box" v-for="(a_item,a_index) in assessList" :key="a_index">
      <div class="item_title_box">
        <h5 v-text="a_item['typeName']"></h5>
        
        <span>{{a_item['certificateTotality']+'/'+a_item['giveLessonsList'].length}}</span>
      </div>
      <div class="list_cont">
        <div class="list_item_line"  >
          <div class="aline_box">
            <a-row :gutter="48">
            <a-col class="gutter-row" :span="8" v-for="line in a_item.giveLessonsList" :key="line.id">
              <div v-if="line.certificateUrl!=null&&line.certificateUrl!=undefined&&line.certificateUrl!=''" class="cer_icon_box" @click="goDetails(line.id)">
                <img :src="QiNiuPath+line.certificateUrl" width="100%" class="cer_bg" alt="">
                <img  src="@/assets/imgs/cer/have_icon.png" class="have" alt="">
                <p class="cer_title">{{line.courseName.startsWith('《')?'':'《'}}{{line.courseName}}{{line.courseName.endsWith('》')?'':'》'}}</p>
              </div>
              <div v-else class="cer_icon_box" @click="goDetails(line.id)">
                <img src="@/assets/imgs/cer/cer_item_icon.png" width="100%" class="cer_bg" alt="" :style="{'opacity':line.certificationStatus!=6?'0.59':''}"/>
                <img :src="line.courseLogo!=null&&line.courseLogo!=''?QiNiuPath+line.courseLogo:require('@/assets/imgs/cer/cer_item_logo.png')" class="cer_logo" alt="" :style="{'opacity':line.certificationStatus!=6?'0.59':''}"/>
                <p class="cer_title">{{line.courseName.startsWith('《')?'':'《'}}{{line.courseName}}{{line.courseName.endsWith('》')?'':'》'}}</p>
              </div>
            </a-col>
          </a-row>
          </div>
        </div>
      </div>
    </div>
    </a-spin>
  </div>
</template>
<script>
import {
  giveLessonsList,
} from '@/apis/certificate.js';
import {mapState} from 'vuex';
export default {
  name: "list",
  data() {
    return {
      lessonsList:[],
      loading:true,
      delayTime: 10,
    };
  },
  computed:{
    ...mapState({
      QiNiuPath:(state)=>state.indexStore.QiNiuPath, 
    }),
    assessList(){
     
      return this.lessonsList.filter((item)=>{
        return item.giveLessonsList!=null&&item.giveLessonsList.length>0;
      })
    }
  },
  created(){
    this.getList();
  },
  methods:{
    //获取列表
    getList(){
      giveLessonsList({}).then(res=>{
        console.log(res);
        let msg=res.data;
        if(msg.status==0){
          this.lessonsList=msg.result.map((item)=>{
            let awardedNumber=item.awardedNumber!=null&&item.awardedNumber!=undefined&&item.awardedNumber!=""?item.awardedNumber:0;
            let certificateTotality=item.certificateTotality!=null&&item.certificateTotality!=undefined&&item.certificateTotality!=""?item.certificateTotality:0;
            return {...item,awardedNumber,certificateTotality}
          });
          this.$nextTick(()=>{
             this.loading=false;
          })
        }
      })
    },
    //go-详情
    goDetails(id){
      this.$router.push({
        path:'/VCertiDetails',
        query: {
						"id":id,
					}
        });
    }
  }
};
</script>
<style lang="scss" scoped>
.list_main {
  width: 100%;
  min-height: 400px;
  max-height:auto;
  text-align: center;
  .course_item_box {
    width: 100%;
    padding-bottom: 17px;
    .item_title_box {
      width: 1200px;
      margin: 0 auto;
      overflow: hidden;
      margin-bottom: 36px;
      h5 {
        min-width: auto;
        max-width: 600px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        float: left;
        padding-left: 17px;
        font-size: 28px;
        color: #333a48;
        position: relative;
        margin-right: 20px;
        &::before {
          position: absolute;
          top: calc(50% - 12px);
          left: 0;
          width: 7px;
          height: 26px;
          background: rgba(116, 101, 255, 1);
          border-radius: 5px;
          content: "";
        }
      }
      span {
        float: left;
        font-size: 28px;
        color: #9aa7b9;
      }
    }
    .list_cont {
      .list_item_line {
        // background-image: url("../../../../assets/imgs/cer/tm_icon.png");
        // background-position-x: center;
        // background-position-y: bottom;
        // background-repeat: no-repeat;
        // background-size:auto 264px;
        // padding-bottom: 122px;
        .aline_box{
          width: 1200px;
          margin: 0 auto;
        }
        .cer_icon_box{
          text-align: center;
          width: 100%;
          position: relative;
          height: 325px;
          margin-bottom: 45px;
          cursor:pointer;
          .have{
            width:96px;
            height: 38px;
            position: absolute;
            top: 0;
            left: -3px;
            z-index: 999;
          }
          .cer_logo{
            width:179px;
            position: absolute;
            top: 90px;
            right: 55px;
          }
          .cer_bg{
            height:276px;
            box-shadow: 0 6px 10px rgb(0 0 0 / 20%);
          }
          .cer_title{
            display: inline-block;
            // background-image: url("../../../../assets/imgs/cer/cer_index_icon.png");
            // background-position-x: center;
            // background-position-y: top;
            // background-repeat: no-repeat;
            // background-size: 100% 100%;
            font-size: 18px;
            font-weight: 500;
            color: #333A48;
            line-height: 25px;
            padding: 5px 8px;
            margin-top:10px;
            min-width: auto;
            max-width:260px;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
          }
        }
      }
    }
  }
}
</style>